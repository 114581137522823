$(document).ready(function () {

    $('input[name="file_main"]').on('change', e => {
        e.preventDefault()

        $.ajax({
            type: "POST",
            url: $(e.target).data('action'),
            data: {
                id: $(e.target).val()
            },
            dataType: 'json',

            success: function (response) {
                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        })
    })

});
