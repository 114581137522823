$(document).ready(function () {
    bsCustomFileInput.init();

    $('#add_more_photo_form').on('submit', function (event) {
        event.preventDefault();

        let form = $(this);

        let data = {
            image: $('#more_product_image').val(),
        };

        if (data.image.length < 2) {
            toastr.warning('Не выбрано фото', {timeOut: 3000});
            return false;
        }

        $.ajax({
            type: "POST",
            url: form.data('action'),
            data: data,
            dataType: 'json',
            success: function (response) {
                let productId = response.product_image.product_id;
                let tbody = $('#tbody_more_photos_' + productId);

                tbody.append(
                    '<tr id="tr_product_image_' + response.product_image.id + '">' +
                    '<td><img src="' + response.url_image + '" width="150" alt=""></td>' +
                    '<td class="text-right"><button class="btn btn-danger btn-sm delete-more-product-photo waves-effect waves-light"' +
                    ' data-action="' + response.remove_route + '"' +
                    ' data-product-id="' + response.product_image.product_id + '"' +
                    ' data-photo-id="' + response.product_image.id + '"><i class="fas fa-trash-alt"></i></button></td>' +
                    '</tr>');

                $('#selected_more_product_image').attr('src', '');
                form.trigger('reset');

                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        });
    });

    $('body').on('click', '.delete-more-product-photo', function (event) {
        event.preventDefault();

        let button = $(this);

        let id = button.data('photoId');
        let action = button.data('action');

        $.ajax({
            type: "POST",
            url: action,
            dataType: 'json',
            success: function (response) {
                $('#tr_product_image_' + id).remove();

                notifySuccess(response.message);
            },
            error: function () {
                notifyError();
            }
        });
    });
});