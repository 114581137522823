$(document).ready(() => {
    $("#form_menu-item #field__select_type").on("change", (e) => {
        const action = $(e.target).data("action");
        const type = $(e.target).val();

        const linkField = $("#field__input_link");
        const relationsField = $("#field__select_entity_id");

        $.ajax({
            type: "POST",
            url: action,
            data: {
                type: type,
            },
            dataType: "json",
            beforeSend: () => {
                linkField.val("").prop("disabled", true);
                relationsField.html("").prop("disabled", true);
            },
            success: function (response) {
                if (response.type === "link") {
                    linkField.val("").prop("disabled", false);
                }

                if (response.is_entity) {
                    relationsField.html("").prop("disabled", false);

                    $.each(response.relations, (key, item) => {
                        relationsField.append(
                            `<option value="${item.id}">${item.title}</option>`,
                        );
                    });
                }

                notifySuccess(response.message);
            },
            error: function (xhr, status, err) {
                notifyError();
            },
        });
    });
});
